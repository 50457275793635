import React, { useRef } from "react";
import { Button, Text } from "@atoms";
import { PortalImage } from "@molecules";
import { m, useScroll, useTransform } from "framer-motion";
// import useLang from "@hooks/useLang";
// import t from "@utils/t";

const StoriesOfChange = ({ introImage, heading, link: button, images }) => {
  // const lang = useLang();

  const outerProgress = useRef(null);
  // Seperate the inner and outter sticky elements
  const { scrollYProgress: outerYProgress } = useScroll({
    target: outerProgress,
    offset: ["start start", "end end"],
  });

  const leftIn = useTransform(outerYProgress, [0.3, 0.7], [-700, 0]);
  const rightIn = useTransform(outerYProgress, [0.3, 0.7], [700, 0]);

  return (
    <div className="h-[150vh]" ref={outerProgress}>
      {/* Parent of the sticky should be as long as you want the animation to go while stuck */}
      <div className="sticky top-4 h-screen">
        <div className="relative mx-auto h-screen w-screen max-w-8xl overflow-hidden py-5">
          {/* Content */}
          <m.div className="absolute inset-0 z-20 flex flex-col items-center justify-center gap-6 pt-[6vw]">
            <Text className="leading-tighter flex w-2/3 items-center justify-center text-center font-magilio text-4xl text-viridian sm:w-1/3 md:text-4xl lg:text-h1">
              {heading}
            </Text>

            {button?.url && (
              <Button to={button?.url} color="black">
                <Text>{button?.text}</Text>
              </Button>
            )}
          </m.div>

          <div className="relative grid h-screen w-full grid-cols-5 grid-rows-3 gap-x-4 sm:gap-x-12 lg:gap-x-16 xl:-left-[10%] xl:w-[120%]">
            {/* Top Row */}
            <m.div
              className="relative col-start-2 row-start-1 max-h-[28vh]"
              style={{ x: leftIn, y: leftIn }}
            >
              <PortalImage
                image={images?.[0]}
                className="absolute bottom-0 left-0 right-0"
              />
            </m.div>
            <m.div
              className="relative col-start-4 row-start-1 max-h-[28vh]"
              style={{ x: rightIn, y: leftIn }}
            >
              <PortalImage
                image={images?.[1]}
                className="absolute bottom-0 left-0 right-0"
              />
            </m.div>

            {/* Middle Row - First Image */}
            <m.div className="row-start-2" style={{ x: leftIn }}>
              <PortalImage image={images?.[2]} />
            </m.div>

            {/* Main animating portal */}
            <div className="relative col-start-3 row-start-2 min-h-[44vh]">
              <m.div
                className="relative z-10"
                // style={{ y }}
              >
                {/* <m.div
                  style={{ scale, opacity }}
                  className="w-full origin-bottom"
                >
                  <PortalImage image={firstImage} pr={5} captionColor="black" />
                </m.div>
                {firstImage?.attribution && (
                  <m.div style={{ opacity }} className="mt-4 ml-0">
                    <Text variant="caption" className="text-black">
                      <span>{t("Photo courtesy of", lang)}</span>
                      <span>{firstImage?.attribution}</span>
                    </Text>
                  </m.div>
                )} */}
              </m.div>
              <m.div
                className="absolute top-0 z-0 w-full"
                // style={{ opacity: backOpacity }}
              >
                <div className="aspect-portal overflow-hidden rounded-t-full bg-white bg-gradient-to-t from-[#A8D99F] to-light-green opacity-60" />
              </m.div>
            </div>

            {/* Middle Row - Last Image */}
            <m.div className="col-start-5 row-start-2" style={{ x: rightIn }}>
              <PortalImage image={images?.[3]} />
            </m.div>

            {/* Bottom Row */}
            <m.div
              className="relative col-start-2 row-start-3 max-h-[28vh]"
              style={{ x: leftIn, y: rightIn }}
            >
              <PortalImage
                image={images?.[4]}
                className="absolute left-0 right-0 top-1 mt-0 sm:-mt-6 md:-mt-10"
              />
            </m.div>
            <m.div
              className="relative col-start-4 row-start-3 max-h-[28vh]"
              style={{ x: rightIn, y: rightIn }}
            >
              <PortalImage
                image={images?.[5]}
                className="absolute left-0 right-0 top-1 mt-0 sm:-mt-6 md:-mt-10"
              />
            </m.div>
          </div>
        </div>
        {/* </Sticky> */}
      </div>
    </div>
  );
};

StoriesOfChange.defaultProps = {};

export default StoriesOfChange;
